<template>
  <div id="media-siputra-page test">
    <b-card no-body class="h-100">
      <!-- header component -->
      <b-card-header>
        <div>
          <!-- title -->
          <b-card-title class="d-flex align-items-center">
            <i class="bi bi-clipboard-plus"></i>
            <span style="margin-left: 5px"> SIPUTRA </span>
          </b-card-title>
        </div>
        <!-- filter component -->
        <div
          class="d-flex flex-wrap gap-5px justify-content-end align-items-center"
        >
          <!-- date filter -->
          <dateRangePicker
            :from_date="filter_data.from_date"
            :to_date="filter_data.to_date"
            @update-value="dateFilterChanged"
          />
          <!-- keyword filter -->
          <b-input-group class="input-group-merge" style="width: 15rem">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              v-model="filter_data.key"
              placeholder="Masukkan kata kunci..."
            />
          </b-input-group>
        </div>
      </b-card-header>
    </b-card>
    <b-card class="h-100">
      <!-- title -->
      <b-card-title class="d-flex align-items-center">
        <span style="margin-left: 5px">
          Grafik Tren Jumlah Input Berita Media Siputra
        </span>
      </b-card-title>
      <!-- loader component -->
      <loader v-if="is_chart_loading" />
      <b-card-body v-else>
        <!-- linechart component -->
        <lineChart v-if="chart_data.categories.length > 0" :data="chart_data" />
        <div v-else class="alert alert-warning mt-1 py-1">
          <center>
            <p class="fs-14">
              <strong>Mohon maaf, data tidak tersedia!</strong>
              Grafik tidak bisa menampilkan data yang kosong
            </p>
          </center>
        </div>
      </b-card-body>
    </b-card>
    <b-card no-body class="h-100">
      <b-card-header>
        <!-- title -->
        <b-card-title class="d-flex align-items-center">
          <span style="margin-left: 5px">
            Daftar Jumlah Input Berita Media Siputra
          </span>
        </b-card-title>
        <div class="d-flex gap-5px justify-content-end">
          <!-- scraper status filter -->
          <div>
            <select
              v-model="filter_data.scraper_status"
              id="Status"
              class="form-control"
              @change="getData()"
            >
              <option value="">Scraper Tidak Tersedia</option>
              <option value="Aktif">Scraper Aktif</option>
              <option value="Nonaktif">Scraper Nonaktif</option>
            </select>
          </div>
        </div>
      </b-card-header>
      <!-- loader component -->
      <loader v-if="is_loading" />
      <!-- dataTable -->
      <dataTable
        v-else
        :data="table_data"
        :is_row_clicked="true"
        :is_pagination="false"
        :viewDetail="viewDetail"
      />
      <paginationInfo
        :page="pagination.page"
        :items="pagination.items"
        :count="pagination.count"
        @update-value="paginationChanged"
      />
    </b-card>
    <!-- detail media modal component -->
    <detailMediaModal ref="detail" />
  </div>
</template>
<script>
import moment from "moment";
import axios from "axios";
import useJwt from "@/auth/jwt/useJwt";
import lineChart from "@/views/components/lineChart.vue";
import dataTable from "@/views/components/dataTable.vue";
import paginationInfo from "@/views/components/paginationInfo.vue";
import loader from "@/views/components/loader.vue";
import dateRangePicker from "@/views/components/dateRangePicker.vue";
import detailMediaModal from "@/views/components/siputra/detailMediaModal.vue";
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BCardBody,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
} from "bootstrap-vue";

export default {
  name: "MediaSiputra",
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardSubTitle,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    paginationInfo,
    lineChart,
    dataTable,
    loader,
    dateRangePicker,
    detailMediaModal,
  },
  data() {
    return {
      is_loading: true,
      is_chart_loading: true,
      filter_data: {
        from_date:
          this.$route?.query?.from_date ||
          moment(new Date()).format("YYYY-MM-DD 00:00:00"),
        to_date:
          this.$route?.query?.to_date ||
          moment(new Date()).format("YYYY-MM-DD 23:59:59"),
        key: "",
        scraper_status: "",
      },
      chart_data: {
        colors: [],
        categories: [],
        series: [],
      },
      pagination: {
        page: 1,
        items: 10,
        count: 0,
      },
      table_data: {
        fields: [
          {
            key: "no",
            label: "no",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "1rem" },
          },
          {
            key: "_id",
            label: "nama media",
            sortable: true,
            thClass: "text-left",
            tdClass: "text-left",
            thStyle: { width: "auto" },
          },
          {
            key: "scraper_name",
            label: "nama scraper",
            sortable: true,
            thClass: "text-left",
            tdClass: "text-left",
            thStyle: { width: "auto" },
          },
          {
            key: "scraper_status",
            label: "status scraper",
            sortable: true,
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "auto" },
          },
          {
            key: "count",
            label: "jumlah berita",
            sortable: true,
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "10rem" },
          },
        ],
        items: [],
      },
      cancel_token: null,
      cancel_chart_token: null,
    };
  },
  created() {
    this.getTrendChart();
    this.getData();
  },
  watch: {
    "filter_data.key"() {
      if (
        this.filter_data.key.trim().length >= 2 ||
        this.filter_data.key.trim().length == 0
      ) {
        this.getTrendChart();
        this.getData();
      }
    },
  },
  methods: {
    dateFilterChanged(data) {
      this.filter_data.from_date = data.from_date;
      this.filter_data.to_date = data.to_date;
      this.getTrendChart();
      this.getData();
    },
    paginationChanged(data) {
      this.pagination = data;
      this.getData();
    },
    getData() {
      this.is_loading = true;
      if (this.cancel_token) {
        this.cancel_token.cancel("ERR_CANCELED");
      }
      this.cancel_token = axios.CancelToken.source();

      let params = {
        from_date: this.filter_data.from_date,
        to_date: this.filter_data.to_date,
        page: this.pagination.page,
        items: this.pagination.items,
        ...(this.filter_data.key ? { name: this.filter_data.key } : {}),
        ...(this.filter_data.scraper_status
          ? { scraper_status: this.filter_data.scraper_status }
          : {}),
      };
      let query = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
      let api = process.env.VUE_APP_API_URL + "media-siputra?" + query;
      useJwt.axiosIns
        .get(api, { cancelToken: this.cancel_token.token })
        .then((res) => {
          this.cancel_token.value = null;
          this.table_data.items = res.data?.media_siputra_data || [];
          this.pagination.count = res.data?.pagination_info?.count || 0;
        })
        .catch((err) => {
          if (err.message != "ERR_CANCELED") {
            this.cancel_token.value = null;
          } else {
            this.cancel_token.value = "CANCELED";
          }
        })
        .finally(() => {
          if (!this.cancel_token.value) {
            this.is_loading = false;
          }
        });
    },
    getTrendChart() {
      this.is_chart_loading = true;
      if (this.cancel_chart_token) {
        this.cancel_chart_token.cancel("ERR_CANCELED");
      }
      this.cancel_chart_token = axios.CancelToken.source();

      this.chart_data = {
        colors: [],
        categories: [],
        series: [],
      };
      let params = {
        from_date: this.filter_data.from_date,
        to_date: this.filter_data.to_date,
        ...(this.filter_data.key ? { name: this.filter_data.key } : {}),
      };
      let query = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
      let api = process.env.VUE_APP_API_URL + "media-siputra/chart?" + query;
      useJwt.axiosIns
        .get(api, { cancelToken: this.cancel_chart_token.token })
        .then((res) => {
          if (res.status === 200) {
            this.cancel_chart_token.value = null;
            this.chart_data.categories = res.data.categories;
            for (let i = 0; i < res.data.series.length; i++) {
              let color = "#28c76f";
              this.chart_data.colors.push(color);
              let data = {
                name: res.data.series[i].name,
                data: res.data.series[i].value,
              };
              this.chart_data.series[i] = data;
            }
          } else {
            this.chart_data = {
              colors: [],
              categories: [],
              series: [],
            };
          }
        })
        .catch((err) => {
          if (err.message != "ERR_CANCELED") {
            this.cancel_chart_token.value = null;
          } else {
            this.cancel_chart_token.value = "CANCELED";
          }
        })
        .finally(() => {
          if (!this.cancel_chart_token.value) {
            this.is_chart_loading = false;
          }
        });
    },
    viewDetail(id) {
      this.$bvModal.show("modal-detail-media");
      this.$refs.detail.getData(id);
      this.$refs.detail.getGrafikData(id);
    },
  },
};
</script>
