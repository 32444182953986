<template>
  <vue-apex-charts
    type="line"
    :height="data.height ? data.height : '300'"
    :options="config"
    :series="data.series"
  />
</template>
  
  <script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "lineChart",
  components: {
    VueApexCharts,
  },

  props: {
    data: Object,
  },

  data() {
    return {
      config: {
        chart: {
          toolbar: { show: false },
          zoom: { enabled: false },
          offsetX: -10,
        },
        stroke: {
          curve: "smooth",
          width: [2, 2, 2, 2, 2, 2, 2, 2],
        },
        legend: {
          show: true,
          // showForSingleSeries: true,
          position: "bottom",
          fontSize: "14px",
          fontFamily: "Montserrat",
          fontWeight: 600,
          horizontalAlign: "center",
          markers: {
            radius: 12,
          },
          labels: {
            colors: "#A9A9A9",
          },
          itemMargin: {
            horizontal: 10,
            vertical: 20,
          },
        },
        colors: this.data.colors,
        markers: {
          size: 5,
          hover: {
            size: 5,
          },
        },
        xaxis: {
          labels: {
            show: true,
            style: {
              colors: "#A9A9A9",
              fontSize: "12px",
              fontFamily: "Montserrat",
              fontWeight: 600,
            },
          },
          categories: this.data.categories,
        },
        yaxis: {
          labels: {
            style: {
              colors: "#A9A9A9",
              fontSize: "12px",
              fontFamily: "Montserrat",
              fontWeight: 600,
            },
            formatter(val) {
              return val > 999 ? `${(val / 1000).toFixed(0)}k` : val;
            },
          },
        },
        grid: {
          borderColor: "#e7eef7",
          padding: {
            top: -20,
            bottom: -10,
            left: 20,
          },
        },
        tooltip: {
          x: { show: false },
        },
      },
    };
  },
};
</script>
  